
.terra-about{
display: flex;
flex-direction: column;
}

.team-section {
  display: flex;
  margin: 0;
  width: 100%;
  gap: 180px;
  padding-bottom: 200px;
  font-family: "Montserrat", sans-serif;

}

.fede-card,
.vero-card {
  display: flex;
  flex: 1;
  padding-top: 20px;
  font-weight: 500;
}



.vero-card {
  background-color: #474748;
}

.fede-card {
  background-color: #4e5c43;
  justify-content: right;
}
.fede-info,
.vero-info {
  display: flex;
  flex-direction: column;
  /* border: solid 3px #ffffff; */
  color: #fff;
  width: 350px;
  max-width: 100%;
}
.member-image,
.vero-info img {

  max-width: 100%;
}


.fede-info {
  background-color: #474748;
  padding-right: 10px;
  margin-bottom: -30px;
  margin-top: 40px;
  /* margin-right: -40px; */
}
.member-image {
  margin-top: -25px;
  margin-right: -60px;
  margin-top: -35px;
  margin-left: 40px;
}
.member-info {
  margin-top: 20px;
  font-size: 16px;
  margin-left: 40px;
  margin-bottom: 15px;
  font-weight: 500;
}
.member-name {
font-weight: 500;

  margin-top: 20px;
  font-size: 18px;
}

.vero-info {
  background-color: #4e5c43;
  padding-right: 10px;
  margin-bottom: -30px;
  margin-top: 40px;
}
.vero-info img {
  margin-top: -35px;
  margin-left: -30px;
}
.vero-info p {
  margin-top: 20px;
  font-size: 16px;
  margin-left: 30px;
  margin-bottom: 15px;
}
.vero-info span {
  margin-top: 20px;
  font-size: 18px;
}

@media screen and (max-width: 1080px) {
  .terra-about{
    margin-bottom: -10.5rem;
    }
  .team-section {
    flex-direction: column;
    gap: 30px;
  }
    .fede-card,
  .vero-card {
    justify-content: center;
    width: 100%;
    margin: 0;
    padding: 0;
    
  }  
  .fede-info,
  .vero-info {
    padding: 0;
    margin: 0;
    width: 500px;

    margin-inline: auto;
    /* margin-top: 46px; */
  }
  .fede-info img,
  .vero-info img{
    padding: 0;
    margin: 0;
    width: 500px;
    margin-inline: auto;
  }
  .fede-info p,
  .vero-info p{
    padding: 0;
    margin-inline: auto;
  }
}
/* 



  .fede-info {
    background-color: #a45a3f;
    padding-left: 0px;
    margin-bottom: 0px;
    margin-top: 30px;
    margin-inline: auto;

  }
  .fede-info img {
    margin-top: -20px;
    margin-inline: auto;

  }

.vero-info {
    padding-right: 0px;
    margin-bottom: -30px;
    margin-top: 30px;
  }
  .vero-info img {
    margin-top: -20px;
    margin-inline: auto;
  }
} */
