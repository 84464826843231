.rammed-home {
  display: flex;
  width: 100%;
  /* background-color: #463c3c; */
  /* background-color: #75402f; */
  background-color: #474748;
  color: #fff;
  margin: 0;
  padding: 0;
  overflow: hidden;
  align-items: center;
}

.rammed-small {
  display: flex;
  flex-direction: column;
}

.small-img {
  display: flex;
  width: fit-content;
  margin: 0;
  max-width: 100%;
}
.small-img img {
  display: block;
  max-width: 250px;
  min-width: 10px;
}

.rammed-home-info {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 3rem;
  max-width: 100%;
  gap: 2rem;
}
.rammed-home-info h1 {
  font-weight: 300;
  font-size: 36px;
}
.rammed-home-info p {
  font-size: 18px;
  line-height: 24px;
}
.rammed-info{
  text-decoration: none;
  color: #fff;
  font-size: 1.1rem;
  border: solid 2px #fff;
  font-weight: 500;
  width: fit-content;
  padding: 8px 30px;
  border-radius: 10px;
}
.rammed-info:hover,
.rammed-info:focus {
  /* color: #607557;
  border: solid 2px #607557; */
  background-color: #607557;
}

@media screen and (max-width: 1080px) {
  .rammed-home {
    flex-direction: column;
  }
  .small-img img {
    max-width: 100%;
  }

  .rammed-home-info {
    padding: 2rem;
    gap: 1.5rem;
  }
  .rammed-home-info h1 {
    font-size: 36px;
  }
}

@media screen and (max-width: 580px) {
  .rammed-home-info {
    padding: 30px 20px;
    gap: 1.5rem;
  }

  .rammed-home-info h1 {
    font-size: 24px;
  }

  .rammed-home-info p {
    font-size: 15px;
  }
}
