.contact-info {
  display: flex;
  flex-direction: column;
  margin: 0;
  background-color: #65755a;
  margin-top: 90px;
  padding-bottom: 30px;
}
  .contact-header {
    display: flex;
    gap: 15px;
    flex-direction: column;
    padding: 40px 100px;
    color: #fff;
    text-align: center;
    margin-bottom: 10px;
  }

  .contact-info h1 {
    font-weight: 500;
    padding-top: 15px;
  }

  .contact-info p {
    font-size: 1.2rem;
    font-weight: 400;
    margin-top: -5px;
  }

.contact-details {
  display: flex;
  margin: 0;
  
}

.contact-logo{
    display: flex;
    width: 100%;
    margin: 0;
    margin-top: -200px;
    margin-bottom: 20px;
}
.contact-logo img{
    width: 230px;
    margin-inline: auto;
    opacity: .2;
}

.contact-terra {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  /* margin-inline:auto */
}

.terra-details{
    color: #fff;
    margin: 15px auto;
}
.terra-details h3{
    margin-top: -15px;
margin-bottom: 5px;
font-weight: 500;
font-size: 1.2rem;
}

.terra-details a{
    text-decoration: none;
    color: #fff;
    margin-bottom: 15px;
    letter-spacing: 1.3px;
font-weight: 500;
font-size: 1.3rem;
}
@media screen and (max-width: 880px) {
    .contact-header {
        padding: 40px;
        color: #fff;
        text-align: center;
        margin-bottom: -10px;
        padding: 1rem;
      }
      .contact-header {
        display: flex;
        gap: 15px;
        flex-direction: column;
        padding: 40px 20px;
        color: #fff;
        text-align: center;
        margin-bottom: -10px;
      }
    
      .contact-info h1 {
        font-weight: 500;
        padding-top: 15px;
      }
    
      .contact-info p {
        font-size: 1rem;
        font-weight: 400;
        margin-top: -5px;
      }
      .contact-info {
      margin-top: 80px;
      }
      .contact-info h1 {
        font-weight: 500;
         font-size: 1.5rem;
      }
      
      .contact-info p {
        font-size: 1rem;
        margin-top: .7rem;
        font-weight: 500;
      }

      .contact-details {
        flex-direction: column;
    
      }
      .terra-details{
        text-align: center;
    }
      .contact-terra{
        margin-bottom: 20px;
    }


.phone-n{
    margin-top: -5px !important;
margin-bottom: 5px;
font-size: 1.2rem !important;
font-weight: 500;
    }
.email-a{
    margin-top: -25px !important;
margin-bottom: 5px;
font-size: 1.2rem;
font-weight: 500;
    }
    .contact-info {

        padding-bottom: 0px;
      }
      .contact-logo img{
        width: 230px;
        margin-inline: auto;
        opacity: .13;
        margin-top:-10px;
        margin-bottom: 10px;

    }
}