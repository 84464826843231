.whatsapp-static {
  display: flex;
  position: fixed;
  background-color: #1a852a;
  border: solid 2px #fff;
  border-radius: 50%;
  width: 70px;
  justify-content: center;
  align-content: center;
  right: 0;
  bottom: 0;
  margin-top: auto;
  margin-left: auto;
  margin-bottom: 20px;
  margin-right: 20px;
  z-index: 9999;
}

.whatsapp-static a {
  text-decoration: none;
  /* padding-top: 40px;
  margin-top: -30px;
  margin-bottom: -5px; */
  margin-top:7px;
  margin-bottom:-5px;
}
.whatsapp-static span {
  font-size: 50px;
  color: #fff;
}
