@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}

.App {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
}

.home,
.about,
.contact {
  display: flex;
  flex-direction: column;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  background-color: #f5f3f4;
  color: #363434;
  position: relative;
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
}

.main-banner {
  position: relative;
  margin-top: 17rem;
  margin-bottom: 5rem;
  left: 0;
  right: 0;
  width: 100%;
}

.page-icon {
  margin-left: 1rem;
  font-size: 100px;
}

#error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.container {
  width: 100%;
  top: 0;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  align-items: center;
  justify-content: center;
  position: absolute;
  font-family: "Montserrat", sans-serif;
  background: transparent;
}

.terra-section {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 20px 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a {
  font-family: "Montserrat", sans-serif;
}

#error-page {
  margin-top: 300px;
  padding: 40px;
  gap: 20px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}
#error-page p {
  margin-top: -5px;
}

.about-terra{
  display: flex;
  flex-direction: column;
  background-image: url(../../assets/rammedEarth/terra-bg-20.webp);
  background-size: cover;  margin: 0;
  padding-top: 120px;
}
.projects-section{
  display: flex;
  flex-direction: column;
  padding-top: 120px;
  background-color: #fff;
  /* color: #474748; */
  color: #333;
  gap: 5px;
}

.contact-page{
  width: 100%;
  top: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background: transparent;
}
@media screen and (max-width: 960px) {
  .home,
  .about,
  .contact {
    font-size: 2.5rem;
  }

  .page-icon {
    margin-left: 1rem;
    font-size: 80px;
  }
}
