.home-top-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.home-top-section img {
  /* display: block; */
  width: 100%;
  margin: 0;
  padding: 0;
}
.top-banner-mob {
  display: none;
}
.top-banner {
  display: block;
}
.home-top-text {
  display: block;
  background: #65755a;
  width: 100%;
  text-align: center;
  margin-top: -2rem;
}

.home-top-text h1 {
  padding: 1rem;
  font-size: 26px;
  color: #fff;
  margin-inline: auto;letter-spacing: 1px;
  font-weight: 400;
  /* padding: 30px;
  border-radius: 10px;
  background: rgba(57, 44, 44, 0.5);
  text-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
  -webkit-text-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
  -moz-text-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
  -o-text-shadow: 0 1px 6px rgba(0, 0, 0, 0.2); */
}

.top-white-logo {
  display: none;
  flex-direction: column;
  position: relative;
  background-color: #617657;
  width: 100%;
}
.top-white-logo img {
  position: relative;
  width: 300px;
  padding: 10px;
  margin-inline: auto;
}

@media screen and (max-width: 980px) {
  .home-top-section {
    margin-top: 80px;
  }
  .top-white-logo img {
    max-width: 230px;
    padding: 0px;
  }
}
@media screen and (max-width: 680px) {
  .top-banner {
    display: none;
  }
  .top-banner-mob {
    display: block;
  }
  .home-top-text {
    display: block;
    margin-top: 0rem;
  }
  .home-top-text h1{
    font-size: 18px;
    margin-top: 0rem;
    padding: .8rem ;
    letter-spacing: 0rem;
  }
  .top-white-logo img {
    max-width: 180px;
    padding: 0px;
  }
}
