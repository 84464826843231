* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.navbar {
  width: 100%;
  background: rgb(255, 255, 255, 0.8);
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  position: fixed;
  top: 0;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
  transition: 0.3s all ease-in-out;
  z-index: 3999;
}

.navbar.colorChange {
  background-color: rgb(255, 255, 255, 1);
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  height: 80px;
}

.nav-container {
  z-index: 1;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 45px;
  padding-left: 65px;
}

.navbar-logo {
  display: flex;
  flex-direction: column;
  color: #646161;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 0.7rem;
  text-transform: uppercase;
  align-items: center;
}

.glow-logo {
  padding-top: 0.5rem;
  padding-bottom: 0.2rem;
  width: 270px;
  min-width: 20px;
}

.navbar-icon {
  margin-right: 0.5rem;
}

.nav-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  text-align: center;
  justify-content: flex-end;
  font-weight: 500;
}

.nav-item {
  height: 90px;
  text-transform: uppercase;
  border-bottom: 2px solid transparent;
}

.nav-item:hover {
  border-bottom: 2px solid #617657;
}

.nav-links {
  /* color: #617657; */
  color: #3b4836;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem .8rem;
  height: 100%;
}

.activated {
  color: #3e2400;
}

.fa-bars {
  color: #363434;
  fill: #363434;
  background: #000;
}
.cg-menu-right {
  color: #363434;
  fill: #363434;
  background: #000;
}

.menu-icon {
  display: none;
  color: #363434;
}

@media screen and (max-width: 1080px) {
  .navbar {
    height: 80px;
    z-index: 2000;
  }

  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    padding: 60px 0;
    padding-top: 100px;
    top: 0;
    right: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: rgb(255, 255, 255, 0.95);
    right: 0;
    top: 0;
    opacity: 1;
    transition: all 0.6s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    color: #3b4836;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item {
    height: 70px;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    width: 100%;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(15%, 50%);
    z-index: 10;
  }

  .glow-logo {
    width: 235px;
    margin-top: -19px;
    z-index: 11;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-110%, 70%);
    font-size: 1.8rem;
    cursor: pointer;
    z-index: 12;
  }

  .menu-icon :nth-child(1) {
    color: #565452;
  }
}

@media screen and (max-width: 460px) {
  .navbar-logo {
    margin-left: -10px !important;
  }
}
