.social-gallery-title{
    margin: 0;
    background-color: #617657;
    padding: 10px;
    margin-bottom: -11px;
    text-align: center;
    color: #fff;
}
.terra-instagram{
    display: flex;
    text-decoration: none;
    color: #fff;
    gap: 5px;
    align-content: center;
    justify-content: center;
    margin-bottom: -5px;
}
.terra-instagram h2{
    font-weight: 400;
}
.instagram-icon{
    font-size: 27px;
}