.latest-projects,
.current-projects {
  display: flex;
  flex-direction: column;
  margin-inline: 0;
  gap: 7px;
}

.projects-header {
  display: flex;
  /* margin-top: -30px; */
  flex-direction: column;
  background-color: #65755a;
  padding: 80px 100px;
  text-align: center;
  margin-bottom: 3px;
}
.projects-header h1 {
  font-weight: 500;
  margin-inline: auto;
  color: #fff;
}
.projects-header p {
  font-weight: 400;
  margin-top: 1.5rem;
  margin-bottom: -1.4rem;
  margin-inline: auto;
  color: #fff;
}
.current-projects-header {
  display: flex;
  /* margin-top: 3px; */
  flex-direction: column;
  padding: 80px 100px;
  background-color: #65755a;
  text-align: center;
  margin-bottom: 3px;
}
.current-projects-header h1 {
  font-weight: 500;
  margin-inline: auto;
  color: #fff;
}
.current-projects-header p {
  font-weight: 400;
  margin-top: 1.5rem;
  margin-bottom: -1.4rem;
  margin-inline: auto;
  color: #fff;
}
.projects-header {
  display: flex;
  margin-top: -30px;
  flex-direction: column;
  padding: 80px 100px;
  background-color: #65755a;
  text-align: center;
  margin-bottom: 3px;
}
.projects-header h1 {
  font-weight: 500;
  margin-inline: auto;
  color: #fff;
}
.projects-header p {
  font-weight: 400;
  margin-top: 1.5rem;
  margin-bottom: -1.4rem;
  margin-inline: auto;
  color: #fff;
}

.terra-projects {
  display: flex;
  background-color: #fff;
  margin-inline: 0px;
  gap: 7px;
  margin-inline: 7px;
}

.terra-b-project {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.project-gallery {
  text-decoration: none;
  margin: 0;
  margin-top: -3px;
}
.project-gallery:hover,
.project-gallery:focus {
  color: #65755a;
}
.apogee-desk {
  display: block;
}
.apogee-mob {
  display: none;
}
.terra-b-project img {
  flex: 1;
  max-width: 100%;
}
.terra-b-project:hover,
.terra-b-project:focus {
  opacity: 0.9;
  color: #fff;
}
.terra-b-project h2 {
  color: #fff;
  margin-top: -50px;
  margin-bottom: 20px;
  margin-left: 30px;
  font-weight: 400;
  text-shadow: 1px 1px 3px #000;
}
.terra-b-project h2:hover,
.terra-b-project h2:focus {
  color: #fff;
}

@media screen and (max-width: 680px) {
  .terra-projects {
    flex-direction: column;
  }
  .projects-header{
    margin-top: -40px;
    padding: 70px 20px;
    margin-bottom: -13px;
    z-index: 1000;
  }
  .current-projects-header {
    padding: 70px 20px;
    margin-bottom: 3px;
  }
  .projects-header h1,
  .current-projects-header h1 {
    font-size: 1.5rem;
  }
  .projects-header p,
  .current-projects-header p {
    font-size: 1rem;
  }
  .apogge-desk {
    display: none;
  }
  .apogee-mob {
    display: block;
  }
  .terra-b-project h2 {
    font-size: 1.4rem;
  }
}
