.homeGalleryWrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  margin: 0px;
  margin-bottom: -19px;
  gap: 5px;
}

.homeGalleryWrap .single {
  cursor: pointer;
  margin: 0;
  margin-top: -4px;
}

.homeGalleryWrap .single img {
  max-width: 100%;
  margin: 0;
}

.homeGalleryWrap .single img:hover,
.homeGalleryWrap .single img:focus {
  /* transform: scale(1.02); */
  opacity: 0.9;
}

.sliderWrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.sliderWrap .btnClose,
.sliderWrap .btnPrev,
.sliderWrap .btnNext {
  position: fixed;
  cursor: pointer;
  font-size: 28px;
  opacity: 1;
  color: #fff;
  z-index: 9999;
}

.btnNext:hover,
.btnPrev:hover,
.btnClose:hover {
  opacity: .7;
}

.sliderWrap .btnClose {
  top: 120px;
  right: 40px;
}

.sliderWrap .btnPrev {
  top: 50%;
  transform: translateY(-50%);
  left: 40px;
}

.sliderWrap .btnNext {
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
}

.fullScreenImage {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullScreenImage img {
  max-width: 100%;
  max-height: 100%;
  pointer-events: none;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

@media screen and (max-width: 1060px) {
  .sliderWrap .btnClose {
    top: 30px;
    right: 40px;
  }
}