.join-info {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 80px auto;
  align-content: center;
  color: #555;
  text-align: center;
  padding-inline: 20px;
}
.join-info p {
  font-size: 20px;
  line-height: 38px;
}
.join-info span {
  font-size: 24px;
  font-weight: 600;
  color: #617657;
}

@media screen and (max-width: 1080px) {
  .join-info {
  margin: 60px auto;

  }
  .join-info p {
    font-size: 17px;
    line-height: 28px;
  }
  .join-info span {
    font-size: 20px;
    font-weight: 600;
  }
}
@media screen and (max-width: 580px) {
  .join-info p {
    font-size: 15px;
    line-height: 28px;
  }
  .join-info span {
    font-size: 20px;
    font-weight: 600;
  }
}

@media screen and (max-width: 370px) {
  .join-info p {
    font-size: 13px;
  }
  .join-info span {
    font-size: 17px;
    font-weight: 600;
  }
}
