.terra-services {
  display: flex;
  flex-direction: column;
  margin-inline: auto;
  width: fit-content;
  padding-top: 180px;
  padding-bottom: 100px;
  gap: 20px;
  font-size: 1.2rem;
  background-image: url(../../assets/terraBrite/terra-water.webp);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.terra-services h1 {
  margin-inline: auto;
  padding-bottom: 20px;
  font-size: 2.2rem;
  font-weight: 500;
  color: #4d5a44;
}
.service-group {
  display: flex;
  gap: 20px;
  margin-inline: 30px;
}
.terra-services span {
  font-weight: 600;
  color: #65755a;
}
.terra-service {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;
  padding: 30px 20px;
  border: solid 1px #ddd;
}

@media screen and (max-width: 1080px) {
  .terra-services {
    padding-top: 140px;
  }

  .terra-services h1 {
    font-size: 1.8rem;
  }

  .service-group {
    flex-direction: column;
  }
}
