.about-info {
 padding-inline:  100px;
 margin-bottom: 50px;
}
.about-info h1{
    text-align: center;
    margin-inline: auto;
    padding: 40px;
    font-size: 40px;
    font-weight:500;
    color: #444;
    font-family: "Montserrat", sans-serif;
  }
  .about-info p{
    margin-inline: auto;
    padding: 20px 0px;
    font-size: 1.2rem;
    line-height: 1.7rem;
    font-weight:400;
    font-family: "Montserrat", sans-serif;
  }
  .about-info span{
    font-weight:500;
  }

  @media screen and (max-width: 1080px) {
    .about-info {
        padding-inline:  30px;
       }

       .about-info h1{
        font-size: 30px;
        padding: 20px;
      }
       .about-info p{
        font-size: 1.05rem;
      }
}