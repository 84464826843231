.project-details {
    color: #fff;
  width: 100%;
  margin-top: 70px;
  padding: 50px;
  background-color: #65755a;
  justify-content: center;
  text-align: center;
  margin-bottom: -10px;
}
.back-to-projects{
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 5rem;
    text-decoration: none;
color: #fff;
/* color: #157130; */
}
.back-to-projects p {
  line-height: 1.8rem;
  font-size: 1.1rem;
  font-weight: 500;
  margin-top: -3px;
}
.back-to-projects span {
    font-size: 1.3rem;
}

.back-to-projects:hover{
  /* color: #0c471d; */
  color: #c7cbc8;
}
.project-title-info{
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 35px;
}
.work-description{
    font-weight: 400;
    margin-bottom: 10px;
}
.work-details{
    font-size: 1.1rem;
    font-weight: 500;
    padding-bottom: 20px;
}



@media screen and (max-width: 1080px) {
  .project-details {
    margin-top: 60px;
  padding-inline: 20px;
}
  /* .back-to-projects{
margin-bottom: 20px;
} */
  .project-title-info{
    font-size: 1.4rem;
    margin-bottom: 25px;
}
.work-description{
    font-size: 1.2rem;
font-weight: 500;
  }
.work-details{
    font-size: .9rem;
line-height: 1.3rem;    padding-bottom: 0px;
}


}