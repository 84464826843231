.home-projects{
    margin: 0;
    background-color: #2c2a2a;
    padding: 10px;
    margin-bottom: -11px;
    text-align: center;
    color: #fff;
}

@media screen and (max-width: 880px) {
    .home-projects{
        margin: 0;
        background-color: #2c2a2a;
        padding: 0px;
        margin-bottom: -11px;
        text-align: center;
        color: #fff;
    }
  }