.terra-bg {
  background-image: url(../assets/rammedEarth/terra-bg-20.webp);
  background-size: cover;
}
.main-info {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 60px 150px;
}
.desk-h1 {
  font-size: 30px;
  font-weight: 400;
  padding-bottom: 15px;
  color: rgb(56, 70, 49);
}

.mob-h1{
  display: none;
}
.main-info h2 {
  font-size: 26px;
  font-weight: 400;
  padding-bottom: 5px;
  color: rgb(56, 70, 49);
}

.main-info p {
  font-size: 16px;
  line-height: 25px;
  color: #111;
}
.main-info span {
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  margin-top: 1.2rem;
  color: #111;
}
.join-info {
  display: flex;
  gap: 15px;
  margin: 60px auto;
  margin-bottom: 50px;
  align-content: center;
  color: #555;
  text-align: center;
}
.join-info p {
  font-size: 20px;
  line-height: 38px;
}
.join-info span {
  font-size: 24px;
  font-weight: 600;
}

@media screen and (max-width: 1080px) {
  .main-info {
    margin: 40px 30px;
  }
  .desk-h1 {
    font-size: 28px;
  }
  .main-info h2 {
    font-size: 23px;
    font-weight: 500;
    padding-bottom: 5px;
    color: rgb(56, 70, 49);
  }
  .main-info p {
    font-size: 16px;
  }
}
@media screen and (max-width: 680px) {
  .main-info {
    margin: 30px 20px;
    gap: 5px;
  }

  .desk-h1 {
    font-size: 23px;
    font-weight: 500;
    padding-bottom: 5px;
  }

  .main-info h2 {
    font-size: 20px;
    padding-bottom: 5px;
  }

  .main-info p {
    font-size: 14px;
    line-height: 20px;
  }
  .main-info span {
    font-size: 15px;
    line-height: 20px;
    margin-top: 15px !important;
    margin-bottom: 5px !important;
  }
}

@media screen and (max-width: 570px) {
  
  .desk-h1{
    display: none;
  }

  .mob-h1 {
    display: block;
    font-size: 21px;
    font-weight: 500;
    padding-bottom: 5px;
  }

  .main-info h2 {
    font-size: 20px;
    padding-bottom: 5px;
  }
  
  .main-info p {
    font-size: 13px;
  }
}

@media screen and (max-width: 370px) {
  .main-info p {
    font-size: 13px;
  }
}
