.footer-container {
  display: flex;
  margin: 0;
  background-color: #4a4949;
}

.footer-col {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 30px 50px;
  justify-content: center;
  align-content: center;
  color: #fff;
}
.first-col {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.footer-col a {
  display: flex;
  text-decoration: none;
  color: #fff;
  gap: 10px;
  font-weight: 500;
}
.footer-col a span {
  font-size: 24px;
}
.logo-col {
  text-align: center;
  align-items: center;
}
.logo-col img {
  width: 350px;
  margin-inline: auto;
}
.logo-col-mob {
  display: none;
}

.last-col {
  text-align: right;
  gap: 0;
  opacity: 0.9;
}
.last-col h1 {
  font-size: 36px;
  font-weight: 600;
}

.last-col h2 {
  font-size: 32px;
  font-weight: 600;
}

@media screen and (max-width: 1080px) {
  .footer-container {
    flex-direction: column;
  }
  .footer-col {
    padding: 20px 50px;
  }
  .first-col {
    margin-inline: auto;
  }

  .logo-col {
    display: none;
  }
  .logo-col-mob {
    display: flex;
    margin-inline: auto;
    margin-top: 40px;
    margin-bottom: 30px;
  }
  .logo-col-mob img {
    width: 280px;
    margin-bottom: -20px;
  }
  .last-col {
    display: none;
  }
}
